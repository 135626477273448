import { Card, Tabs, Typography } from "antd";
import ChatQueue from "./LiveChatTabs/ChatQueue";
import ChatLogs from "./LiveChatTabs/ChatLogs";
import OpenChat from "./LiveChatTabs/OpenChat";
import { checkPermission } from "../../Helpers/Functions";
import { AssignSelf, getchatqueue } from "../../Axios/services/LiveChatQue";
import { changeLoader } from "../../Redux/reducers/loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSocket, initializeSocket } from "../../Socket/socket";
import addDeleteGetLocalStorage from "../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { changeTrigger } from "../../Redux/reducers/trigger";
import { changeReadchatMessage } from "../../Redux/reducers/readchatMessage";
import { chatMessageCount } from "../../Axios/services/OpenChat";

const LiveChat = () => {
  const { TabPane } = Tabs;
  const [chatQueue, setChatQueue] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  let [pageCount, setPageCount] = useState(0);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(10);
  const [itemTotal, setItemTotal] = useState(0);
  const [tabvalue, setTabvalue] = useState("queue");
  const [count, setCount] = useState(0)
  const readchatMessage = useSelector((state) => state?.readchatMessage);
  const [openCount, setOpenCount] = useState(0)
  const [openChatCount, setOpenChatCount] = useState({})
  let [chatQueueCount, setChatQueueCount] = useState(0);
  const permissionChnage = useSelector((state) => state.permissionChange.value);

  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "updatedAt",
  });
  const [clientId, setClientId] = useState(null);
  let token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  token = jwtDecode(token);
  const dispatch = useDispatch();
  const handleGetAllchatqueue = async () => {
    dispatch(changeLoader(true));
    try {
      let parms = { page: page, limit: limit, status: tabvalue, ...sorting };
      let responce = await getchatqueue(parms);
      if (responce.ack) {
        setChatQueue(responce?.data);
        setItemTotal(responce?.data?.count);
        tabvalue == "queue" && setChatQueueCount(responce?.data?.count);
        // tabvalue == "open" && setOpenCount(responce?.data?.count);
        setPageCount(Math.ceil(responce?.data?.count / limit));
      }
      dispatch(changeLoader(false));
    } catch (err) {
      console.log(err);
      dispatch(changeLoader(false));
    }
  };

  const handleSort = (order, sortby) => {
    let obj = {
      order: order,
      sort_by: sortby,
    };
    setSorting(obj);
  };
  const handlePick = async (id) => {
    try {
      let data = {
        chat_room_id: id
      };
      let responce = await AssignSelf(data);
      if (responce.ack) {

        localStorage.setItem('client_id', responce?.data?.client_id)
        setClientId(responce?.data?.client_id);
        //   handleGetAllchatqueue();
        setPage(1);
        setChatQueue([]);
        setTabvalue("open");
        setChatQueueCount(chatQueueCount - 1);
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (newPage) => {
    page = newPage;
    setPage(newPage);
    handleGetAllchatqueue();
  };

  useEffect(() => {
    let socket = getSocket();
    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      if (checkPermission("live_chat", "view-chat-queue")) {
        socket?.on("new-chat-room", (data) => {
          if (data?.chat_room?.id) {
            chatQueueCount == 0 && setChatQueueCount(chatQueueCount + 1);
            if (tabvalue == "queue") {
              handleGetAllchatqueue();
            }
          }
        });
      }
      socket?.on("chat-room-closed", (data) => {
        if (data) {
          handleGetAllchatqueue();
          // handleChatMessageCount();
          chatQueueCount > 0 && setChatQueueCount(chatQueueCount - 1);
          // dispatch(changeTrigger("chatRoomCLosed"));
        }
      });
    };
    readCount();
    if (socket) {
      return () => {
        socket?.off("new-chat-room");
        socket?.off("chat-room-closed");
      };
    }
  }, [tabvalue, chatQueueCount, permissionChnage, socketConnected]);

  useEffect(() => {
    let socket = getSocket();

    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }

      const chatReadCountListener = (data) => {
        setOpenChatCount(data?.chat_message);
        dispatch(changeTrigger("countValueChanged"));
        setCount(data?.readCount);
      };

      socket?.on(`chat-read-count-${token?.user_id}`, chatReadCountListener);
    };

    readCount();

    return () => {
      if (socket) {
        socket?.off(`chat-read-count-${token?.user_id}`);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSocketConnected(!socketConnected);
    }, 3000);
  }, []);

  useEffect(() => {
    handleGetAllchatqueue();
  }, [tabvalue, sorting]);

  return (
    <>
      <Card>
        <div className="flex justify-between mb-4 gap-5">
          <Typography className="text-xl font-semibold" align={"left"}>
            Live Chat
          </Typography>
        </div>
        <div className="relative">
          <Tabs
            defaultActiveKey="1"
            activeKey={tabvalue}
            onTabClick={(e) => {
              if (e == tabvalue) return;
              setSorting({
                order: e == "closed" ? "ASC" : "DESC",
                sort_by: "updatedAt",
              });
              setChatQueue([]);
              setTabvalue(e);
              setPageCount(0);
              setPage(1);
            }}
          >
            {" "}
            <TabPane tab={`Chat Queue  (${chatQueueCount})`} key="queue">
              <ChatQueue
                chatQueue={chatQueue}
                itemTotal={itemTotal}
                handlePick={handlePick}
                handleGetAllchatqueue={handleGetAllchatqueue}
                handlePageChange={handlePageChange}
                page={page}
                limit={limit}
                pageCount={pageCount}
                setPage={setPage}
                setLimit={setLimit}
              />
            </TabPane>{" "}
            {checkPermission("live_chat", "chat-history") && (
              <TabPane tab="Chat Logs" key="closed">
                <ChatLogs
                  handleSort={handleSort}
                  itemTotal={itemTotal}
                  chatQueue={chatQueue}
                  handleGetAllchatqueue={handleGetAllchatqueue}
                  handlePageChange={handlePageChange}
                  page={page}
                  limit={limit}
                  pageCount={pageCount}
                  setPage={setPage}
                  setLimit={setLimit}
                />
              </TabPane>
            )}
            <TabPane tab={`Open Chat`} key="open"  >
              <OpenChat
                openChatCount={openChatCount}
                tabvalue={tabvalue}
                clientId={clientId}
                setChatQueue={setChatQueue}
                itemTotal={itemTotal}
                chatQueue={chatQueue}
                handleGetAllchatqueue={handleGetAllchatqueue}
                handlePageChange={handlePageChange}
                page={page}
                limit={limit}
                pageCount={pageCount}
                setPage={setPage}
                setLimit={setLimit}
              />
            </TabPane>{" "}
          </Tabs>
        </div>
      </Card>
    </>
  );
};
export default LiveChat;
